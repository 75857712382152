<template>    
  <Layout :tituloPagina="'Sistema | Registro de Actualizaciones'">
    <div id="registroActualizaciones">
      <div class="row">
        <div class="col-lg-5">
          <cmp-secciones seccion="registro-actualizaciones"></cmp-secciones>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="accordion accordion-flush" id="registrosActualizaciones">
                <div class="accordion-item" v-for="(registro,index) in registros" :key="index">
                  <h2 class="accordion-header" id="registros">
                    <button
                      class="accordion-button fw-medium"
                      :class="index != 0 ? 'collapsed': ''"
                      type="button"
                      data-bs-toggle="collapse"
                      :aria-expanded="index == 0 ? true: false"
                      :aria-controls="`#registros_actualizacion_${index}`"
                      :data-bs-target="`#registros_actualizacion_${index}`"
                    >
                      Versión {{ registro.version }}
                      <small>{{formatoFecha(registro.created_at)}}</small>
                    </button>
                  </h2>
                  <div
                    :id="`registros_actualizacion_${index}`"
                    class="accordion-collapse collapse"
                    :class="index == 0 ? 'show': 'hidden'"
                    aria-labelledby="registros"
                    data-bs-parent="#registrosActualizaciones"
                  >
                    <div class="accordion-body">
                      <div class="text-muted">
                        <span v-if="!atributoVacio(registro.actualizaciones_seguridad)">
                          <b>Actualizaciones de seguridad:</b>
                          <pre>{{ registro.actualizaciones_seguridad }}</pre>
                        </span>
                        <span v-if="!atributoVacio(registro.correcciones_bugs)">
                          <b>Corrección de bugs:</b>
                          <pre>{{ registro.correcciones_bugs }}</pre>
                        </span>
                        <span v-if="!atributoVacio(registro.mejoras)">
                          <b>Mejoras:</b>
                          <pre>{{ registro.mejoras }}</pre>
                        </span>
                        <span v-if="!atributoVacio(registro.ultimas_novedades)">
                          <b>Últimas novedades:</b>
                          <pre>{{ registro.ultimas_novedades }}</pre>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <paginador
          :pag="page"
          :totpag="totpag"
          :epp="epp"
          @cargar-pagina="cargarPagina"
          @epp-actualizado="
            epp = $event,
            refrescarRegistrosActualicaiones()
          "
        ></paginador>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import RegistroActualizacionesSistemaSrv from '@/services/RegistroActualizacionesSistemaSrv.js'
import Paginador from '@/components/Paginador.vue'
import CmpSecciones from './CmpSecciones.vue'
import moment from 'moment'
export default {
  name: 'RegistroActualizaciones',
  components:{
    Layout,
    CmpSecciones,
    Paginador
  },
  data: function (){
    return {
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.RegistroActualizaciones.por_pagina') || 10),
      tr: 0, // Total de registros de actualizacion
      totpag: 0, // Total de páginas
      registros: []
    }
  },
  created: function() {
    var self = this

    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp)  self.epp  = parseInt(self.$route.query.epp)

    self.refrescarRegistrosActualicaiones()
  },
  methods: {
    atributoVacio: function(atributo) {
      if (atributo == '' || atributo == null || atributo == undefined)
        return true
      return false
    },
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarRegistrosActualicaiones({ page: n })
    },
    formatoFecha: function(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    refrescarRegistrosActualicaiones: function(){
      var self = this

      this.$router.replace({
        query: {
          page: self.page,
          epp: self.epp
        }
      }).catch(error => {})

      let params = {
        epp: self.epp,
        page: self.page
      }
      
      RegistroActualizacionesSistemaSrv.registrosJSON(params).then(response => {
        localStorage.setItem('argusblack.RegistroActualizaciones.por_pagina', self.epp)
        let page = response.data
        self.registros = page.data
        self.tr = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarRegistrosActualicaiones()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudieron cargar los registros de actualizacion'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
pre {
  overflow-x: auto;
  border: none;
  display: block;
  font-family: Arial, Helvetica, sans-serif, "Helvetica Neue", Helvetica;
  text-align: justify;
  background: none;
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
small {
  position: absolute;
  right: 50px;
}
</style>